<template>
  <v-container class="layout-container">
    <BaseHeader
      :title="$t('privacy.title')"
      :style-variant="9"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t('privacy.subtitle') }}</p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>

    <v-card
      v-if="hasSeperateAvv"
      outlined
      class="my-2 pa-4 pa-md-8"
    >
      <span>{{ $t('privacy.title-avv') }}:
        <router-link
          class="text-decoration-none"
          to="/avv"
        >
          <span class="text-decoration-underline">{{ $t('privacy.read-more-accept') }}</span>
        </router-link>
      </span>
    </v-card>

    <Feature :feature-slug="featureNames.LICENSE_AGREEMENT">
      <v-card
        outlined
        class="my-2 pa-4 pa-md-8"
      >
        <span>{{ $t('privacy.title-license-agreement') }}:
          <router-link
            class="text-decoration-none"
            to="/license-agreement"
          >
            <span class="text-decoration-underline">{{ $t('privacy.read-more-accept') }}</span>
          </router-link>
        </span>
      </v-card>
    </Feature>

    <v-card
      outlined
      class="my-2 pa-4 pa-md-8"
    >
      <span>{{ $t('privacy.title-privacy') }}:
        <a
          class="text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
          href="https://bottimmo.com/de/datenschutzerklaerung/"
        > <span class="text-decoration-underline">{{ $t('privacy.read-more') }}</span>
          <v-icon
            class="ml-2"
            size="14"
            color="primary"
          >
            mdi-open-in-new
          </v-icon>
        </a>
      </span>
    </v-card>
    <v-card
      outlined
      class="my-2 pa-4 pa-md-8"
    >
      <span>{{ $t('privacy.title-text-examples') }}:
        <a
          class="text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
          href="https://bottimmo.com/de/datenschutz-textbausteine/"
        > <span class="text-decoration-underline">{{ $t('privacy.read-more') }}</span>
          <v-icon
            class="ml-2"
            size="14"
            color="primary"
          >
            mdi-open-in-new
          </v-icon>
        </a>
      </span>
    </v-card>
  </v-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/privacy/HeaderDesign.vue'
import featureMixin from '@/mixins/feature'

export default {
  components: { BaseHeader, HeaderDesign },
  mixins: [featureMixin],
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('navigation.labels.imprint'), to: { path: '/legal' }, disabled: false, exact: true },
        { text: this.$t('privacy.title') }
      ]
    },
    hasSeperateAvv () {
      const licenseAgreementFeature = this.getFeature(this.featureNames.LICENSE_AGREEMENT)
      return !(licenseAgreementFeature && licenseAgreementFeature.isActive && licenseAgreementFeature.config.hasAvv)
    }
  }
}
</script>
